import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/footer.module.scss"

import Menu from "../molecules/wp-menu"

// Constants
import { FOOTER } from "./../../utils/constants"

// SVGs
import NHSLogo from "./../../images/svgs/footer/logo-nhs.svg"
import SCCLogo from "./../../images/svgs/footer/logo-scc.svg"

function Footer({ menu }) {
  return (
    <footer className={styles.footer}>
      <div className={styles.upperContainer}>
        <div className={styles.nhsLogoContainer}>
          <a
            href="https://www.nhs.uk/"
            target="_blank"
            rel="noreferrer"
            aria-label="NHS Website"
          >
            <div className={styles.svgBox}>
              <NHSLogo />
            </div>
          </a>
        </div>
        <div className={styles.menu}>
          <Menu mode={FOOTER} items={menu.nodes} />
        </div>
        <div className={styles.sccLogoContainer}>
          <div className={styles.svgBox}>
            <a
              href="https://www.picker.org/"
              target="_blank"
              rel="noreferrer"
              aria-label="Picker Website"
            >
              <SCCLogo />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.copyright}>
          <div>Copyright © {new Date().getFullYear()} NHS Staff Survey</div>
          <div>
            Site by{" "}
            <a
              href="https://www.design-culture.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              Design Culture
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  /**
   * The footer's WP Menu object
   */
  menu: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
}
