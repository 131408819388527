import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useMetaData } from "../../hooks/use-meta-data"

const SEO = ({ description, lang, meta, robots, slug, title }) => {
  const site = useMetaData()
  const structuredDataWebPage = `[{"@context": "http://schema.org","@type": "WebPage","name": "${title}","@id": "${site.url}${slug}","url": "${site.url}${slug}","inLanguage": "en-GB","isPartOf": [{"@type": "WebSite","@id": "${site.url}","name": "${site.title}","inLanguage": "en-GB","description": "${site.description}"}]}]`

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          {
            name: "robots",
            content: robots,
          },
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
        ].concat(meta)}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: structuredDataWebPage,
        }}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  robots: "noindex",
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  robots: PropTypes.string,
}

export default SEO
