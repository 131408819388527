/**
 * It accepts multi-level Wordpress menus
 */
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./__styles__/wp-menu.module.scss"
import { useSelector, connect } from "react-redux"
import { updateMobileMenuMode } from "./../../redux/actions"

// Constants
import { HEADER, FOOTER, MOBILE, DESKTOP } from "./../../utils/constants"

// SVGs
import OpenMenu from "./../../images/svgs/header/mobilebutton.svg"
import CloseMenu from "./../../images/svgs/header/close-menu.svg"

// Redux mapping
const mapStateToProps = state => {
  return {
    responsiveMode: state.responsiveMode,
  }
}

const mapDispatchToProps = {
  updateMobileMenuModeAction: updateMobileMenuMode,
}

const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = []
  const childrenOf = {}
  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}

const getParentPath = pageURI => {
  const regex = /^\/(.*?(?=[\/]))\//gm
  const match = regex.exec(pageURI)
  if (match !== null) {
    return match[0]
  }
  return false
}

const checkActivePath = (pageURI, path) => {
  if (pageURI === path || getParentPath(pageURI) === path) {
    return true
  }
  return false
}

const ListMenu = ({ data, hasChildren }) => {
  const pageURI = useSelector(state => state.pageURI)
  const activeClass = checkActivePath(pageURI, data.path) ? styles.active : ""

  return (
    <li key={data.label} className={activeClass}>
      <Link to={data.path}>{data.label}</Link>
      {hasChildren && (
        <SubMenu
          active={checkActivePath(pageURI, data.path)}
          data={data.children}
          key={data.label}
        />
      )}
    </li>
  )
}

ListMenu.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  hasChildren: PropTypes.arrayOf(PropTypes.shape({})),
}

const SubMenu = ({ active, data }) => {
  const activeClass = active ? styles.active : ""
  return (
    <ul className={`${activeClass} ${styles.submenu}`}>
      {data.map(menu => {
        return (
          <ListMenu
            data={menu}
            hasChildren={menu.children.length ? menu.children : null}
            key={menu.label}
          />
        )
      })}
    </ul>
  )
}

SubMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.bool,
}

class Menu extends React.Component {
  static modeClass(mode) {
    if (mode === HEADER) {
      return styles.header
    }
    return ""
  }

  static disableScroll() {
    if (typeof document !== "undefined") {
      document.body.style.height = "100%"
      document.body.style.overflow = "hidden"
      document.body.parentNode.style.height = "100%"
      document.body.parentNode.style.overflow = "hidden"
    }
  }

  static enableScroll() {
    if (typeof document !== "undefined") {
      document.body.style.height = "auto"
      document.body.style.overflow = "initial"
      document.body.parentNode.style.height = "auto"
      document.body.parentNode.style.overflow = "initial"
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false,
      loaded: false,
    }
  }

  componentDidMount() {
    this.loaded()
  }

  componentDidUpdate() {
    const { responsiveMode } = this.props
    const { mobileMenuOpen } = this.state
    if (responsiveMode === DESKTOP && mobileMenuOpen) {
      this.mobileMenuClose()
    }
  }

  loaded() {
    this.setState({
      loaded: true,
    })
  }

  mobileMenuSwitch() {
    const { updateMobileMenuModeAction } = this.props
    const { mobileMenuOpen } = this.state
    updateMobileMenuModeAction(!mobileMenuOpen)
    this.setState({
      mobileMenuOpen: !mobileMenuOpen,
    })
  }

  mobileMenuClose() {
    this.setState({
      mobileMenuOpen: false,
    })
  }

  render() {
    const { mode, items, responsiveMode } = this.props
    const { mobileMenuOpen, loaded } = this.state

    const enableMenuButton =
      (loaded && responsiveMode === MOBILE && mode === HEADER) ||
      (!loaded && responsiveMode === DESKTOP && mode === HEADER)
    const mobileClass =
      responsiveMode === MOBILE && mode === HEADER ? styles.mobile : ""

    const showMenu =
      responsiveMode === DESKTOP ||
      mode === FOOTER ||
      (responsiveMode === MOBILE && mobileMenuOpen)

    if (mobileMenuOpen) {
      Menu.disableScroll()
    } else {
      Menu.enableScroll()
    }

    const navLabel = mode === HEADER ? "Main Menu" : "Footer Menu"

    return (
      <>
        <nav
          aria-label={navLabel}
          className={`${styles.menu} ${Menu.modeClass(mode)} ${mobileClass}`}
        >
          {enableMenuButton && (
            <button
              className={styles.mobilebutton}
              onClick={() => {
                this.mobileMenuSwitch()
              }}
            >
              <span>Menu button</span>
              {mobileMenuOpen && <CloseMenu />}
              {!mobileMenuOpen && <OpenMenu />}
            </button>
          )}
          {showMenu && (
            <ul>
              {flatListToHierarchical(items).map((menu, index) => {
                return (
                  <ListMenu
                    data={menu}
                    hasChildren={menu.children.length ? menu.children : null}
                    key={index}
                  />
                )
              })}
            </ul>
          )}
        </nav>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      parentId: PropTypes.string,
    })
  ),
  mode: PropTypes.string,
  responsiveMode: PropTypes.string,
  updateMobileMenuModeAction: PropTypes.func,
}
