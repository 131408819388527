import React from "react"
import PropTypes from "prop-types"

export default function HeroBg({ colour }) {
  return (
    <svg
      width={3000}
      height={523}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Hero Background</title>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M2320.5 475.8c174.4 0 525.8 28.9 678.8 44.2V0H1.3C.3 136.3-2.9 522.8-.5 522.8c250 0 692-48 777-48 622 0 613 41 859 41s467-40 685-40z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M677 474c-174.4 0-524 31.7-677 47V1h2998c1 136.3 1 520 1 520-250 0-693-48-778-48-622 0-613 41-859 41s-467-40-685-40z"
          fill="url(#prefix__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={1412}
          y1={523}
          x2={1412}
          y2={-23}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#F8F9F9" />
          <stop offset={0.9} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={1585.1}
          y1={39.3}
          x2={1455.7}
          y2={932.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.3} stopColor="#F4FBFA" stopOpacity={0.3} />
          <stop offset={1} stopColor={colour} stopOpacity={0.1} />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h3000v523H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

HeroBg.propTypes = {
  /**
   * The background colour's hex code
   */
  colour: PropTypes.string,
}

HeroBg.defaultProps = {
  colour: "#00A499",
}
