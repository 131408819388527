import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/hero.module.scss"

import HeroBg from "./../atoms/heroBg"
import Copy from "./../atoms/copy"

// SVGs
import SvgHomepage from "./../../images/svgs/illustration/hero-homepage.svg"
import SvgResults from "./../../images/svgs/illustration/hero-results.svg"
import SvgInteractiveDashboard from "./../../images/svgs/illustration/hero-interactive-dashboard.svg"
import SvgLocalResults from "./../../images/svgs/illustration/hero-local-results.svg"
import SvgNationalResults from "./../../images/svgs/illustration/hero-national-results.svg"
import SvgInteractiveResults from "./../../images/svgs/illustration/hero-interactive-results.svg"
import SvgResultsArchive from "./../../images/svgs/illustration/hero-results-archive.svg"
import SvgAboutTheSurvey from "./../../images/svgs/illustration/hero-about-the-survey.svg"
import SvgSurveyDocuments from "./../../images/svgs/illustration/hero-survey-documents.svg"
import SvgFAQs from "./../../images/svgs/illustration/hero-faqs.svg"
import SvgContactUs from "./../../images/svgs/illustration/hero-contact-us.svg"

const pages = {
  // Homepage
  cG9zdDo3: {
    colour: "#00A499",
    illustration: <SvgHomepage />,
  },
  // Results
  "cG9zdDozMQ==": {
    colour: "#78BE20",
    illustration: <SvgResults />,
  },
   // NEW Interactive Dashboard
   "cG9zdDoxODcw": {
    colour: "#ED8B00",
    illustration: <SvgInteractiveDashboard />,
  },
  // Local Results
  "cG9zdDoxMDI=": {
    colour: "#ED8B00",
    illustration: <SvgLocalResults />,
  },
  // National Results
  "cG9zdDoxMDU=": {
    colour: "#41B6E6",
    illustration: <SvgNationalResults />,
  },
  // Bank Worker Results
  "cG9zdDoxNjAy": {
    colour: "#41B6E6",
    illustration: <SvgNationalResults />,
  },
  // Interactive Results
  "cG9zdDoxMDg=": {
    colour: "#AE2573",
    illustration: <SvgInteractiveResults />,
  },
  // Results Archive
  "cG9zdDoxMTE=": {
    colour: "#33B6AE",
    illustration: <SvgResultsArchive />,
  },
  // About the Survey
  "cG9zdDoyOA==": {
    colour: "#78BE20",
    illustration: <SvgAboutTheSurvey />,
  },
  // Survey Documents
  "cG9zdDozNA==": {
    colour: "#ED8B00",
    illustration: <SvgSurveyDocuments />,
  },
  // FAQs
  "cG9zdDo4NQ==": {
    colour: "#41B6E6",
    illustration: <SvgFAQs />,
  },
  // Contact
  "cG9zdDo5NQ==": {
    colour: "#AE2573",
    illustration: <SvgContactUs />,
  },
}

export default function Hero({ title, lead, pageId }) {
  const hasNoIllustrationClass =
    pages[pageId] && pages[pageId].illustration
      ? ""
      : styles.wrapper__noIllustration

  return (
    <div className={[styles.wrapper, hasNoIllustrationClass].join(" ")}>
      <section className={styles.container}>
        <div className={styles.copyContainer}>
          <h1 className={styles.title}>{title}</h1>
          {lead && (
            <div className={styles.lead}>
              <Copy copy={lead} />
            </div>
          )}
        </div>
        {pages[pageId] && pages[pageId].illustration && (
          <div className={styles.illustration}>
            <div className={styles.svgBox}>{pages[pageId].illustration}</div>
          </div>
        )}
      </section>
      {pages[pageId] && pages[pageId].colour && (
        <HeroBg colour={pages[pageId].colour} />
      )}
    </div>
  )
}

Hero.propTypes = {
  /**
   * The title of the page
   */
  title: PropTypes.string.isRequired,
  /**
   * The lead paragraph
   */
  lead: PropTypes.string,
  /**
   * The current's page ID
   */
  pageId: PropTypes.string,
}

Hero.defaultProps = {
  pageId: "",
}
