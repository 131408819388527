import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./__styles__/header.module.scss"
import { useSelector, useDispatch } from "react-redux"
import { globalHistory } from "@reach/router"
import { updateMobileMenuMode } from "./../../redux/actions"

import Menu from "../molecules/wp-menu"

// Constants
import { HEADER, MOBILE } from "./../../utils/constants"

// SVGs
import NHSLogoHeader from "./../../images/svgs/header/nhs-logo-header.svg"

function Header({ menu }) {
  const mobileMenuOpen = useSelector(state => state.mobileMenuOpen)
  const responsiveMode = useSelector(state => state.responsiveMode)
  const dispatch = useDispatch()

  const scrollClass =
    responsiveMode === MOBILE && mobileMenuOpen ? styles.scroll : ""

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        dispatch(updateMobileMenuMode(false))
      }
    })
  })

  return (
    <header className={`${styles.header} ${scrollClass}`}>
      <div className={styles.inner}>
        <Link className={styles.linkHome} to="/" aria-label="Home">
          <NHSLogoHeader />
        </Link>
        <Menu mode={HEADER} items={menu.nodes} />
      </div>
    </header>
  )
}

export default Header

Header.propTypes = {
  /**
   * The site's title
   */
  siteTitle: PropTypes.string.isRequired,
  /**
   * The header's WP Menu object
   */
  menu: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
}
