import React from "react"
import { useMenusData } from "../../hooks/use-menus-data"
import "normalize.css"
import * as styles from "./__styles__/base.module.scss"
import PropTypes from "prop-types"

import Header from "../organisms/header"
import Footer from "../organisms/footer"
import CookieBanner from "../molecules/cookie-banner"

const Layout = ({ children }) => {
  const data = useMenusData()

  return (
    <div className={styles.wrapper}>
      <CookieBanner />
      <div className={styles.bypassLink}>
        <div>
          <div>
            <a href="#main" rel="nofollow">Skip to main content</a>
          </div>
        </div>
      </div>
      <Header
        siteTitle={data.site.generalSettings.title}
        menu={data.mainMenu}
        currentPath={data.currentPath}
      />
      <div id="main" className={styles.container}>
        <main>{children}</main>
      </div>
      <Footer menu={data.footerMenu} />
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}
