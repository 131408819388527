import { useStaticQuery, graphql } from "gatsby"

export const useMenusData = () => {
  const data = useStaticQuery(graphql`
    query {
      site: wp {
        generalSettings {
          title
        }
      }
      mainMenu: allWpMenuItem(
        filter: { locations: { eq: GATSBY_HEADER_MENU } }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          id
          label
          path
          parentId
        }
      }
      footerMenu: allWpMenuItem(
        filter: { locations: { eq: GATSBY_FOOTER_MENU } }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          id
          label
          path
          parentId
        }
      }
    }
  `)

  return data
}
