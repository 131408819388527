import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./__styles__/button.module.scss"

export default function Button({ link, type, colour, buttonFunction, title }) {
  const bgColourStyle = {
    backgroundColor: colour,
  }

  return (
    <>
      {buttonFunction ? (
        <>
          <button
            className={[styles.button, styles[type]].join(" ")}
            style={bgColourStyle}
            onClick={() => {
              buttonFunction()
            }}
          >
            {title}
          </button>
        </>
      ) : (
        <>
          {link.target === "_blank" || !link.url.includes("nhsstaffsurveys") ? (
            <a
              className={[styles.button, styles[type]].join(" ")}
              href={link.url}
              target={link.target === "_blank" ? "_blank" : ""}
              style={bgColourStyle}
              rel="noreferrer"
            >
              {link.title}
            </a>
          ) : (
            <Link
              className={[styles.button, styles[type]].join(" ")}
              to={new URL(link.url).pathname}
              style={bgColourStyle}
            >
              {link.title}
            </Link>
          )}
        </>
      )}
    </>
  )
}

Button.propTypes = {
  /**
   * The button's link object
   */
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  /**
   * The button's type
   */
  type: PropTypes.string,
  /**
   * The button's colour
   */
  colour: PropTypes.string,
  /**
   * Create functional button instead
   */
  buttonFunction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /**
   * Title if not link
   */
  title: PropTypes.string,
}

Button.defaultProps = {
  link: {
    title: "Find out more",
    target: "",
    url: "",
  },
  type: "default",
  colour: "#005EB8",
  buttonFunction: false,
  title: "",
}
