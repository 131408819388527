import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./__styles__/cookie-banner.module.scss"
import { useSelector } from "react-redux"

// Components
import Button from "./../atoms/button"

const CookieBanner = () => {
  const mobileMenuOpen = useSelector(state => state.mobileMenuOpen)
  const cookieControlSet = () => {
    if (typeof document !== "undefined") {
      if (document.cookie.indexOf("CookieControl=") < 0) {
        return false
      }
      return true
    }
    return true
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          privacy: wpPage(databaseId: { eq: 123 }) {
            uri
          }
        }
      `}
      render={data => (
        <>
          {!mobileMenuOpen && (
            <CookieBannerComponent
              cookieControlSet={cookieControlSet()}
              data={data}
            />
          )}
        </>
      )}
    />
  )
}

class CookieBannerComponent extends React.Component {
  static setCookie(name, value, days) {
    if (typeof document !== "undefined") {
      let expires = ""
      if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toUTCString()}`
      }
      document.cookie = `${name}=${value || ""}${expires}; path=/`
    }
  }

  constructor(props) {
    super(props)

    this.privacyURI = props.data.privacy.uri

    this.state = {
      showBanner: !props.cookieControlSet,
    }

    // Binds
    this.cookiesAccepted = this.cookiesAccepted.bind(this)
    this.cookiesRejected = this.cookiesRejected.bind(this)
  }

  cookiesAccepted() {
    CookieBannerComponent.setCookie("CookieControl", true, 365)

    this.setState({
      showBanner: false,
    })
  }

  cookiesRejected() {
    CookieBannerComponent.setCookie("CookieControl", false, 365)

    this.setState({
      showBanner: false,
    })
  }

  render() {
    const { showBanner } = this.state
    return (
      <div suppressHydrationWarning={true} className={styles.bannerWrapper}>
        {showBanner && (
          <div className={styles.banner}>
            <div className={styles.inner}>
              <div className={styles.row}>
                <div className={`${styles.col1}`}>
                  <p>
                    This website uses cookies to ensure you get the best
                    experience on our website.{" "}
                    <a
                      className="cookie-consent__info"
                      rel="noreferrer"
                      target="_blank"
                      href={this.privacyURI}
                    >
                      Read our Privacy Policy.
                    </a>
                  </p>
                </div>
                <div className={`${styles.col2}`}>
                  <Button
                    title="I&rsquo;m happy to accept the cookies"
                    buttonFunction={this.cookiesAccepted}
                  />
                </div>
                <div className={`${styles.col2}`}>
                  <div className={styles.reject}>
                    <Button
                      title="Reject cookies"
                      buttonFunction={this.cookiesRejected}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default CookieBanner

CookieBannerComponent.propTypes = {
  data: PropTypes.shape({
    privacy: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
  cookieControlSet: PropTypes.bool,
}
