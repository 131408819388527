import { useStaticQuery, graphql } from "gatsby"

export const useMetaData = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site: wp {
        generalSettings {
          title
          description
          url
        }
      }
    }
  `)

  return {
    title: site.generalSettings.title,
    description: site.generalSettings.description,
    url: site.generalSettings.url.replace("cms", "www").replace("/wp", ""),
  }
}
