import React from "react"
import Proptypes from "prop-types"
import parse from "html-react-parser"
import * as styles from "./__styles__/copy.module.scss"

export default function Copy({ copy }) {
  /**
   * WordPress' ACF WYSIWYG editor saves internal links with its
   * full URL. Here we remove part of the URL leaving only the slug.
   */
  function removeCMSUrl(html) {
    return html.replace(process.env.GATSBY_CMS_URL, "")
  }

  return <div className={styles.container}>{parse(removeCMSUrl(copy))}</div>
}

Copy.propTypes = {
  /**
   * HTML copy
   */
  copy: Proptypes.string.isRequired,
}
